import { Money } from 'models/money';
import { Card } from 'models/card';
import { ValidRegion } from 'lib/constants';

export function formatMoney(
  money: Money,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
) {
  const dollars = money.amount / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: money.currencyCode,
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export function formatCardBrand(card: Card) {
  return card.brand.replace('_', ' ');
}

export function formatPhoneNumber(phoneNumber: string | number) {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumber;
}

export const formatRegionQueryParam = (
  regionQueryParam: string | string[] | undefined,
): ValidRegion | null => {
  if (regionQueryParam) {
    let sanitizedRegion;
    if (Array.isArray(regionQueryParam)) {
      sanitizedRegion = regionQueryParam[0];
    } else {
      sanitizedRegion = regionQueryParam;
    }
    if (Object.keys(ValidRegion).includes(sanitizedRegion)) {
      return sanitizedRegion as ValidRegion;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
