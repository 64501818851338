/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';
import '../../common/token';
import '../../common/user_attributes';
import './launch_darkly_project';
import '../../common/data_type';
import './status';

const $root = ($protobuf.roots['local-offers-fe']|| ($protobuf.roots['local-offers-fe'] = new $protobuf.Root()));

if (
  !$root?.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags
    ?.nested?.message?.nested?.GetFlagsRequest
) {
$root.addJSON({
  "squareup": {
    "nested": {
      "feature": {
        "nested": {
          "relay": {
            "nested": {
              "flags": {
                "nested": {
                  "message": {
                    "nested": {
                      "GetFlagsRequest": {
                        "fields": {
                          "flagRequests": {
                            "type": "squareup.feature.relay.flags.message.GetFlagRequest",
                            "id": 1,
                            "rule": "repeated"
                          },
                          "userToken": {
                            "type": "squareup.feature.relay.common.Token",
                            "id": 3,
                            "rule": "optional"
                          },
                          "userAttributes": {
                            "type": "squareup.feature.relay.common.UserAttributes",
                            "id": 4,
                            "rule": "optional"
                          },
                          "projectId": {
                            "type": "string",
                            "id": 2
                          },
                          "project": {
                            "type": "squareup.feature.relay.flags.message.LaunchDarklyProject",
                            "id": 5
                          }
                        },
                        "nested": {
                          "flag_project": {
                            "oneof": [
                              "projectId",
                              "project"
                            ]
                          }
                        }
                      },
                      "GetFlagRequest": {
                        "fields": {
                          "flagName": {
                            "type": "string",
                            "id": 2,
                            "rule": "optional"
                          },
                          "flagType": {
                            "type": "squareup.feature.relay.common.DataType",
                            "id": 3,
                            "rule": "optional"
                          },
                          "userToken": {
                            "type": "squareup.feature.relay.common.Token",
                            "id": 4,
                            "rule": "optional"
                          },
                          "userAttributes": {
                            "type": "squareup.feature.relay.common.UserAttributes",
                            "id": 5,
                            "rule": "optional"
                          },
                          "projectId": {
                            "type": "string",
                            "id": 1
                          },
                          "project": {
                            "type": "squareup.feature.relay.flags.message.LaunchDarklyProject",
                            "id": 6
                          }
                        },
                        "nested": {
                          "flag_project": {
                            "oneof": [
                              "projectId",
                              "project"
                            ]
                          }
                        }
                      },
                      "GetFlagsResponse": {
                        "fields": {
                          "status": {
                            "type": "squareup.feature.relay.flags.message.Status",
                            "id": 1,
                            "rule": "optional"
                          },
                          "flagResponses": {
                            "type": "squareup.feature.relay.flags.message.GetFlagResponse",
                            "id": 2,
                            "rule": "repeated"
                          }
                        }
                      },
                      "GetFlagResponse": {
                        "fields": {
                          "status": {
                            "type": "squareup.feature.relay.flags.message.Status",
                            "id": 1,
                            "rule": "optional"
                          },
                          "flagName": {
                            "type": "string",
                            "id": 3,
                            "rule": "optional"
                          },
                          "flagType": {
                            "type": "squareup.feature.relay.common.DataType",
                            "id": 4,
                            "rule": "optional"
                          },
                          "userToken": {
                            "type": "squareup.feature.relay.common.Token",
                            "id": 5,
                            "rule": "optional"
                          },
                          "userAttributes": {
                            "type": "squareup.feature.relay.common.UserAttributes",
                            "id": 6,
                            "rule": "optional"
                          },
                          "projectId": {
                            "type": "string",
                            "id": 2
                          },
                          "project": {
                            "type": "squareup.feature.relay.flags.message.LaunchDarklyProject",
                            "id": 11
                          },
                          "stringValue": {
                            "type": "string",
                            "id": 7
                          },
                          "intValue": {
                            "type": "int32",
                            "id": 8
                          },
                          "boolValue": {
                            "type": "bool",
                            "id": 9
                          },
                          "doubleValue": {
                            "type": "double",
                            "id": 10
                          }
                        },
                        "nested": {
                          "flag_project": {
                            "oneof": [
                              "projectId",
                              "project"
                            ]
                          },
                          "value": {
                            "oneof": [
                              "stringValue",
                              "intValue",
                              "boolValue",
                              "doubleValue"
                            ]
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
});
}

/**
 * SOURCE GetFlagsRequest @ squareup/feature/relay/flags/message/get_flags.proto at 15:1
 */
export const GetFlagsRequest = $root.lookupType('squareup.feature.relay.flags.message.GetFlagsRequest');
fixType(GetFlagsRequest, {
  flagRequests: { required: true },
});
Builder.createAndAttachToType(GetFlagsRequest);
/**
 * SOURCE GetFlagRequest @ squareup/feature/relay/flags/message/get_flags.proto at 38:1
 */
export const GetFlagRequest = $root.lookupType('squareup.feature.relay.flags.message.GetFlagRequest');
fixType(GetFlagRequest, {
  flagName: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(GetFlagRequest);
/**
 * SOURCE GetFlagsResponse @ squareup/feature/relay/flags/message/get_flags.proto at 65:1
 */
export const GetFlagsResponse = $root.lookupType('squareup.feature.relay.flags.message.GetFlagsResponse');
fixType(GetFlagsResponse);
Builder.createAndAttachToType(GetFlagsResponse);
/**
 * SOURCE GetFlagResponse @ squareup/feature/relay/flags/message/get_flags.proto at 73:1
 */
export const GetFlagResponse = $root.lookupType('squareup.feature.relay.flags.message.GetFlagResponse');
fixType(GetFlagResponse, {
  flagName: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(GetFlagResponse);
