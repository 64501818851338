import Cookies from 'js-cookie';
import reactRpc, { $UrlForMethod } from '@square/react-rpc-client';
import { ConsoleLoggerFactory } from '@square/react-rpc-client/utils';
import { RpcError } from '../rpc/model/Error';
import environment from 'lib/environment';

const loggingEnabled = environment === 'development';

export default function rpc<Req, Res>(urlForMethod: $UrlForMethod<Req, Res>) {
  return reactRpc({
    urlForMethod,
    loggerFactory: new ConsoleLoggerFactory(loggingEnabled),
    errorDeserializer: RpcError.fromResponse,
    additionalHeaders: new Map([
      ['X-CSRF-Token', Cookies.get('_js_csrf[square-buyer]') as string],
    ]),
  });
}
