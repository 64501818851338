import {
  MarketButton,
  MarketButtonDropdown,
  MarketList,
  MarketRow,
} from '@market/react';
import ES2Tracker from '../../lib/eventstream/tracker';
import styles from './location-dropdown.module.css';
import { pageClickEvent } from '../../lib/eventstream/event-catalog';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { useRegion } from 'hooks/use-region';
import { REGION_NOT_AVAILABLE, REGION_NAME_MAP } from 'lib/constants';
import { useAccount } from 'hooks/use-account';

const LocationDropdown = () => {
  const [labels, setLabels] = useState(REGION_NAME_MAP);

  const { selectedRegion, updateUnauthUserRegion } = useRegion();
  const { account } = useAccount();

  useEffect(() => {
    setLabels(labels);
  }, [labels]);

  const sortByOptions = Object.entries(labels).map(
    ([selectedRegion, label]) => (
      <MarketRow
        className={`market-row ${styles.dropdownOption}`}
        value={selectedRegion}
        key={`market-row-${selectedRegion}`}
      >
        <label slot="label">{label}</label>
      </MarketRow>
    ),
  );

  const trackButtonDropdownOpenClick = () => {
    const es2Params = {
      detail: JSON.stringify({
        ...(account && { account: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Location Open: ${selectedRegion}`,
        es2Params,
      ),
    );
  };

  const trackListSelectionDidChangeClick = (sortByValue: string) => {
    const es2Params = {
      detail: JSON.stringify({
        category: sortByValue.toLowerCase(),
        ...(account && { account: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Location Filter: ${selectedRegion}`,
        es2Params,
      ),
    );
  };

  const handleOnChange = (event: any) => {
    const selectedRegion = event.detail.newSelection.value;
    updateUnauthUserRegion(selectedRegion);
    trackListSelectionDidChangeClick(selectedRegion);
  };

  // location dropdown is not shown when region is not available. Region modal should pop up when region is not available.
  if (selectedRegion === REGION_NOT_AVAILABLE) {
    return null;
  }

  return (
    <div
      className={`local-offers__body__header__buttons ${styles.locationDropdown}`}
    >
      <MarketButtonDropdown
        className={styles.dropdownButton}
        interaction="click"
        disabled={false}
        no-caret={true}
        popover-placement="bottom-start"
        persist-list={true}
        onMarketButtonDropdownOpened={trackButtonDropdownOpenClick}
      >
        <MarketButton className="market-button" slot="trigger">
          <div slot="icon">
            <FontAwesomeIcon icon={faLocationArrow} />
          </div>
          <div>{labels[selectedRegion]}</div>
        </MarketButton>
        <MarketList
          value={selectedRegion}
          onMarketListSelectionsDidChange={handleOnChange}
          interactive
          slot="content"
        >
          {sortByOptions}
        </MarketList>
      </MarketButtonDropdown>
    </div>
  );
};

export default LocationDropdown;
