import {
  useQuery,
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from '@tanstack/react-query';
import {
  GET_SELECTED_REGION_QUERY_KEY,
  ValidRegion,
  REGION_NOT_AVAILABLE,
  QUERY_PARAM_REGION,
  REGION_NOT_AVAILABLE_TYPE,
  DEFAULT_CENTER,
  GET_GEOLOCATION_QUERY_KEY,
} from 'lib/constants';
import { getRegionCookie, setRegionCookie } from 'lib/cookie';
import { useAccount } from './use-account';
import { useRouter } from 'next/router';
import { formatRegionQueryParam } from 'lib/formatters';
import { LatLng } from 'lib/geolocation';
import { pageClickEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';
import { useState } from 'react';
import { removeQueryParam } from 'lib/query-param-helpers';

export function useRegion(
  initialRegion: ValidRegion | REGION_NOT_AVAILABLE_TYPE = REGION_NOT_AVAILABLE,
): {
  isLoadingRegion: boolean;
  isSelectedRegionFetched: boolean;
  selectedRegion: ValidRegion | REGION_NOT_AVAILABLE_TYPE;
  updateUnauthUserRegion: UseMutateFunction<
    string,
    unknown,
    ValidRegion,
    unknown
  >;
  isValidRegion: boolean;
  isLoadingGeolocation: boolean;
  centerLatLng: LatLng;
  isEnabledGeolocation: boolean | null;
  refetchGeolocation: () => void;
} {
  const { account, isLoading: isAccountLoading } = useAccount();
  const router = useRouter();
  const {
    isLoading: isLoadingRegion,
    data: selectedRegion,
    isFetched: isSelectedRegionFetched,
  } = useQuery(
    [GET_SELECTED_REGION_QUERY_KEY],
    (): ValidRegion | REGION_NOT_AVAILABLE_TYPE => {
      const {
        query: {
          [QUERY_PARAM_REGION]: unsanitizedRegionQueryParams,
        },
      } = router;
      const regionQueryParam = formatRegionQueryParam(
        unsanitizedRegionQueryParams,
      );
      const regionCookie = getRegionCookie();
      if (account && account.region !== REGION_NOT_AVAILABLE) {
        setRegionCookie(account.region);
        return account.region;
      }
      if (regionQueryParam) {
        setRegionCookie(regionQueryParam);
        return regionQueryParam;
      }
      if (regionCookie) {
        return regionCookie;
      }
      return REGION_NOT_AVAILABLE;
    },
    {
      enabled: !isAccountLoading,
      initialData: initialRegion ?? REGION_NOT_AVAILABLE,
    },
  );

  const queryClient = useQueryClient();

  const { mutate: updateUnauthUserRegion } = useMutation(
    async (newSelectedRegion: ValidRegion) => {
      return newSelectedRegion;
    },
    {
      onMutate: newSelectedRegion => {
        setRegionCookie(newSelectedRegion);
        removeQueryParam(QUERY_PARAM_REGION);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SELECTED_REGION_QUERY_KEY]);
      },
      onError: () => {
        queryClient.setQueriesData([GET_SELECTED_REGION_QUERY_KEY], () => [
          REGION_NOT_AVAILABLE,
        ]);
      },
    },
  );

  const [isEnabledGeolocation, setEnabledGeolocation] = useState(false);

  const {
    isLoading: isLoadingGeolocation,
    data: geolocationData,
    refetch: refetchGeolocation,
  } = useQuery(
    [GET_GEOLOCATION_QUERY_KEY],
    async () => {
      const { centerLatLng }: any = await new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject),
      )
        .then((result: any) => {
          const { latitude, longitude } = result.coords;
          setEnabledGeolocation(true);
          ES2Tracker.trackPageClick(
            pageClickEvent(
              'button-click',
              `Marketing: Buyer: Marketplace: Geolocation Accept: ${selectedRegion}`,
            ),
          );
          return {
            centerLatLng: { lat: latitude, lng: longitude },
          };
        })
        .catch((error: GeolocationPositionError) => {
          if (error.code === 1) {
            ES2Tracker.trackPageClick(
              pageClickEvent(
                'button-click',
                `Marketing: Buyer: Marketplace: Geolocation Decline: ${selectedRegion}`,
              ),
            );
            setEnabledGeolocation(false);
          }
          return isSelectedRegionFetched? {centerLatLng: DEFAULT_CENTER[selectedRegion]} : null;
        });
      return {
        centerLatLng,
      };
    },
    {
      enabled: false && !isLoadingRegion,
      retry: false,
      refetchOnWindowFocus: true,
    },
  );

  return {
    isLoadingRegion,
    isSelectedRegionFetched,
    selectedRegion,
    updateUnauthUserRegion,
    isValidRegion: Boolean(selectedRegion !== REGION_NOT_AVAILABLE),
    isLoadingGeolocation,
    centerLatLng:
      geolocationData?.centerLatLng || DEFAULT_CENTER[selectedRegion],
    isEnabledGeolocation,
    refetchGeolocation,
  };
}
