import { DataType } from 'rpc/model/squareup/feature/relay/common/data_type';
import { Token } from 'rpc/model/squareup/feature/relay/common/token';
import {
  GetFlagRequest,
  GetFlagsRequest,
  GetFlagResponse,
} from 'rpc/model/squareup/feature/relay/flags/message/get_flags';
import { Status } from 'rpc/model/squareup/feature/relay/flags/message/status';

import flagsClient from 'clients/flag-client';

export const FLAGS = [
  'local-offers-fe-under-development',
  'local-offers-fe-enable-cash-app-linking',
] as const;
const NO_USER_TOKEN = 'NO_USER_TOKEN';

export async function fetchFlags(
  // We need to always pass in a string value for userToken
  // When we do not have it (no authenticated session)
  // We pass in an invalid string which would return the default value for the flag
  userToken: string = NO_USER_TOKEN,
): Promise<readonly GetFlagResponse[]> {
  const response = await flagsClient.getFlags(
    GetFlagsRequest.create({
      projectId: 'pie',
      userToken: {
        token: userToken,
        type: Token.Type.ANONYMOUS_VISITOR,
      },
      flagRequests: FLAGS.map(flag => {
        const { flagName, flagType } = new GetFlagRequest.Builder()
          .flagName(flag)
          .flagType(DataType.BOOLEAN)
          .build();

        return GetFlagRequest.create({ flagName, flagType });
      }),
    }),
  );

  if (response.status !== Status.SUCCESS) {
    throw new Error('Failed to fetch feature flags.');
  }

  return response.flagResponses;
}
