export type EnvironmentName = 'production' | 'staging' | 'development';

export const domains: { [key: string]: string } = {
  development: 'http://localhost:3000',
  staging: 'https://localoffers.squareupstaging.com',
  production: 'https://localoffers.squareup.com',
};

export enum RUNTIME {
  NODE = 'node',
  BROWSER = 'browser',
}

export function getRuntime(): RUNTIME {
  return typeof process === 'object' && process?.release?.name === 'node'
    ? RUNTIME.NODE
    : RUNTIME.BROWSER;
}

export const runtime = getRuntime();

export const CASHBACKER_DOMAIN = {
  // For calls made from the node server, we use Node Service Container's connector fetch
  // https://github.com/squareup/node-service-container/blob/main/packages/connector
  // And in using so, we want to send requests as relative paths in which NSC's fetch will handle sending requests via envoy
  [RUNTIME.NODE]: {
    development: 'https://localoffers.squareupstaging.com',
    staging: '',
    production: '',
  },
  // And in the browser, we want to send requests to the full url
  // while in development, we want to send to localhost:3000,
  // which in turn will be proxied to cashbacker to avoid CORS errors
  [RUNTIME.BROWSER]: {
    development: '',
    staging: 'https://localoffers.squareupstaging.com',
    production: 'https://localoffers.squareup.com',
  },
};

export const loginDomains: { [key: string]: string } = {
  development: 'https://localoffers.squareupstaging.com',
  staging: 'https://localoffers.squareupstaging.com',
  production: 'https://localoffers.squareup.com',
};

export const squareupDomains: { [key: string]: string } = {
  development: 'https://squareupstaging.com',
  staging: 'https://squareupstaging.com',
  production: 'https://squareup.com',
};

export const squareProfileDomains: { [key: string]: string } = {
  development: 'https://profile.squareupstaging.com',
  staging: 'https://profile.squareupstaging.com',
  production: 'https://profile.squareup.com',
};

export const cashAppDomains: { [key: string]: string } = {
  development: 'https://cashstaging.app',
  staging: 'https://cashstaging.app',
  production: 'https://cash.app',
};

type HostPatternsByEnvironment<Type extends string> = {
  readonly [Property in Type]?: RegExp[];
};

const FALLBACK_ENVIRONMENT: EnvironmentName = 'development';

const hostPatternsByEnvironment: HostPatternsByEnvironment<EnvironmentName> = {
  production: [
    /^squarelocaloffers\.com$/,
    /^local-offers-fe\.sqprod\.co$/,
    /^localoffers\.squareup\.com$/,
  ],
  staging: [
    /^squarelocaloffersstaging\.com$/,
    /^local-offers-fe\.stage\.sqprod\.co$/,
    /^localoffers\.squareupstaging\.com$/,
  ],
  development: [
    /^local-offers-fe\.squareup\.com\.([^.]+\.)+local$/,
    /^localhost:3000$/,
  ],
};

/**
 * Determine the environment based on a given hostname.
 *
 * @param {string} hostname
 * @returns {EnvironmentName} The name of the environment
 */
export function getEnvironmentForHostname(hostname: string): EnvironmentName {
  const matchedEnvironment = Object.entries(hostPatternsByEnvironment).find(
    ([, patterns]) => patterns.find(exp => exp.test(hostname)),
  )?.[0];

  return matchedEnvironment as EnvironmentName;
}

/**
 * Get the current environment using a mix of detection techniques most
 * appropriate for the given execution context (client|server).
 *
 * @param {string} [forHostname] This function will behave like an alias of
 * {@link getEnvironmentForHostname} when this is provided.
 *
 * @returns {EnvironmentName} The name of the environment
 */
export function getEnvironment(forHostname?: string): EnvironmentName {
  if (typeof window !== 'undefined' || forHostname) {
    const hostname = forHostname || window.location.hostname;

    return getEnvironmentForHostname(hostname) || FALLBACK_ENVIRONMENT;
  } else {
    return (process.env.ENVIRONMENT as EnvironmentName) || FALLBACK_ENVIRONMENT;
  }
}

const environment = getEnvironment();

export default environment;
