const PIXEL_ID = '808727203618208';
const facebookEvents = {
  completeRegistration: 'CompleteRegistration',
};
export class FacebookPixelTracker {
  async trackPageView() {
    const { default: ReactPixel } = await import('react-facebook-pixel');
    ReactPixel.init(PIXEL_ID);
    ReactPixel.pageView();
  }

  async trackCompleteRegistration(data?: Record<string, unknown>) {
    const { default: ReactPixel } = await import('react-facebook-pixel');
    ReactPixel.init(PIXEL_ID);
    ReactPixel.track(facebookEvents.completeRegistration, data);
  }
}

export const PixelTracker = new FacebookPixelTracker();
