import ApplicationError, {
  ApplicationErrorProperties,
} from './application-error';

export interface FetchErrorProperties {
  url: string;
  status: number;
}

/**
 * Extends ApplicationError to provide more detail about fetch errors.
 *
 * @see {@link ApplicationError}
 */
export default class FetchError extends ApplicationError {
  url: string;
  status: number;

  constructor(
    message: string,
    {
      url,
      status,
      ...props
    }: ApplicationErrorProperties & FetchErrorProperties,
  ) {
    super(message, props);

    this.url = url;
    this.status = status;
  }
}
