import Head from 'next/head';
import { domains, getEnvironment } from 'lib/environment';

const socialShareUrl = domains[String(getEnvironment())];
const socialShareImageUrl = `${socialShareUrl}/share-image-1.png`;
const socialShareDescription =
  'Shop local. Support your community. Get rewarded.\n\nAs a thank you for shopping locally, Square is offering you weekly offers to your favorite participating businesses. Offers may change weekly.';

const Header = () => (
  <Head>
    <title>Square Local Offers</title>
    <meta property="og:type" content="website" />
    <meta property="og:url" content={socialShareUrl} />
    <meta property="og:title" content="Square Local Offers" />
    <meta property="og:description" content={socialShareDescription} />
    <meta property="og:image" content={socialShareImageUrl} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:domain" content={socialShareUrl} />
    <meta name="twitter:title" content="Square Local Offers" />
    <meta name="twitter:description" content={socialShareDescription} />
    <meta name="twitter:image" content={socialShareImageUrl} />
    <meta name="twitter:url" content={socialShareUrl} />
    <meta name="twitter:label1" content="localoffers.squareup.com/" />
    <meta name="description" content={socialShareDescription} />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" /> 
    <link rel="icon" href="/favicon.ico" />
  </Head>
);

export default Header;
