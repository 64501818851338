/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['local-offers-fe']|| ($protobuf.roots['local-offers-fe'] = new $protobuf.Root()));

if (
  !$root?.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.common
    ?.nested?.Token
) {
$root.addJSON({
  "squareup": {
    "nested": {
      "feature": {
        "nested": {
          "relay": {
            "nested": {
              "common": {
                "nested": {
                  "Token": {
                    "fields": {
                      "token": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "type": {
                        "type": "squareup.feature.relay.common.Token.Type",
                        "id": 2,
                        "rule": "optional"
                      }
                    },
                    "nested": {
                      "Type": {
                        "values": {
                          "UNKNOWN": 0,
                          "UNIT": 1,
                          "MERCHANT": 2,
                          "DEVICE_ID": 3,
                          "ANONYMOUS_VISITOR": 4
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
});
}

/**
 * SOURCE Token @ squareup/feature/relay/common/token.proto at 10:1
 */
export const Token = $root.lookupType('squareup.feature.relay.common.Token');
fixType(Token);
Builder.createAndAttachToType(Token);
/**
 * The type of the token
 *
 * SOURCE Type @ squareup/feature/relay/common/token.proto at 15:3
 */
Token.Type = $root.lookupEnum('squareup.feature.relay.common.Token.Type').values;
