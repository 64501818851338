import router from 'next/router';
import { encode } from 'querystring';

export const removeQueryParam = (param: string) => {
  const { pathname, query } = router;
  const params = new URLSearchParams(encode(query));
  params.delete(param);
  router.replace({ pathname, query: params.toString() }, undefined, {
    shallow: true,
  });
};
