import environment from 'lib/environment';
import { UnifiedErrorReporterFunction } from 'lib/error-reporters/unified-error-reporter';

/**
 * Send errors to the console in development
 *
 * @param {Error} err
 */
const ConsoleErrorReporter = <UnifiedErrorReporterFunction>(
  function (err: Error) {
    if (environment === 'development') {
      // eslint-disable-next-line no-console
      console.error?.(err);
    }
  }
);

export default ConsoleErrorReporter;
