import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { fetchFlags, FLAGS } from 'services/flags';

import {
  getAccount,
  activateAccount as _activateAccount,
  updateAccountEmail as _updateAccountEmail,
  updateAccountRegion as _updateAccountRegion,
  linkWithCash as _linkWithCash,
  LinkWithCashUrls,
} from 'clients/cashbacker-client';

import { Account } from 'models/account';

import {
  ValidRegion,
  REGION_NOT_AVAILABLE_TYPE,
  GET_ACCOUNT_QUERY_KEY,
} from 'lib/constants';

type RedirectFlow = {
  redirectTo: string;
  when: (account: Account | null | undefined) => boolean;
};

type UseAccountOptions = {
  hasSessionCookie?: boolean;
  redirectFlows?: Array<RedirectFlow>;
  include?: string[];
};

export const REQUIRE_ACCOUNT: RedirectFlow = {
  redirectTo: '/',
  when: account => !account,
};

export const REQUIRE_ACTIVE_ACCOUNT: RedirectFlow = {
  redirectTo: '/',
  when: account => account?.status !== 'ACTIVE',
};

export function useAccount(options: UseAccountOptions = {}): {
  account: Account | null;
  isLoading: boolean;
  canUse: (flagName: (typeof FLAGS)[number]) => boolean;
  activateAccount: () => Promise<Account>;
  updateAccountEmail: (email: string) => Promise<Account>;
  updateAccountRegion: (
    region: ValidRegion | REGION_NOT_AVAILABLE_TYPE,
  ) => Promise<Account>;
  linkWithCash: () => Promise<LinkWithCashUrls>;
} {
  const { redirectFlows } = {
    redirectFlows: [],
    ...options,
  };
  const include =
    !options.include || options.include.length === 0
      ? ['earnings']
      : options.include;
  const router = useRouter();
  const { isInitialLoading: isLoading, data } = useQuery(
    [GET_ACCOUNT_QUERY_KEY, include],
    async () => {
      if (!options.hasSessionCookie) {
        return {
          account: null,
          featureFlags: [],
        };
      }

      try {
        const { account } = await getAccount({ include });
        const featureFlags = await fetchFlags(account?.id);

        return {
          account,
          featureFlags,
        };
      } catch {
        return {
          account: null,
          featureFlags: [],
        };
      }
    },
    {
      retry: false,
      staleTime: Number.POSITIVE_INFINITY,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: options.hasSessionCookie || false,
    },
  );

  const account = data?.account || null;
  const featureFlags = data?.featureFlags || [];

  const canUse = useCallback(
    (flagName: string) => {
      return (
        (featureFlags || []).find(flag => flag.flagName === flagName)
          ?.boolValue || false
      );
    },
    [featureFlags],
  );

  const activateAccount = useCallback(async (): Promise<Account> => {
    const { account } = await _activateAccount();
    return account;
  }, []);

  const updateAccountEmail = useCallback(
    async (email: string): Promise<Account> => {
      const { account } = await _updateAccountEmail(email);
      return account;
    },
    [],
  );

  const updateAccountRegion = useCallback(
    async (
      region: ValidRegion | REGION_NOT_AVAILABLE_TYPE,
    ): Promise<Account> => {
      const { account } = await _updateAccountRegion(region);
      return account;
    },
    [],
  );

  const linkWithCash = useCallback(async (): Promise<LinkWithCashUrls> => {
    const { redirectUrl, qrCodeImageUrl } = await _linkWithCash();
    return {
      redirectUrl,
      qrCodeImageUrl,
    };
  }, []);

  const [pageLoadComplete, setPageLoadComplete] = useState<boolean>(false);
  useEffect(() => {
    if (!isLoading && !pageLoadComplete) {
      // for (const redirectFlow of redirectFlows) {
      //   if (redirectFlow.when(account)) {
      //     router.push(redirectFlow.redirectTo);
      //     break;
      //   }
      // }

      setPageLoadComplete(true);
    }
  }, [
    pageLoadComplete,
    setPageLoadComplete,
    redirectFlows,
    router,
    isLoading,
    account,
  ]);

  return {
    isLoading,
    account,
    canUse,
    activateAccount,
    updateAccountEmail,
    updateAccountRegion,
    linkWithCash,
  };
}
