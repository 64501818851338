import * as Sentry from '@sentry/nextjs';
import { UnifiedErrorReporterFunction } from 'lib/error-reporters/unified-error-reporter';

/**
 * Send errors to Sentry via Sentry.captureException
 *
 * @param {Error} err
 */
const SentryErrorReporter = <UnifiedErrorReporterFunction>(
  function (err: Error) {
    Sentry.captureException(err);
  }
);

export default SentryErrorReporter;
