import { REGION_NOT_AVAILABLE_TYPE, ValidRegion } from 'lib/constants';

type Event = Record<string, unknown>;

export const routeToPageView: { [key: string]: string } = {
  '': 'Marketing: Buyer: Marketplace',
  '/': 'Marketing: Buyer: Marketplace',
  '/map': 'Marketing: Buyer: Marketplace: Map',
  '/menu': 'Marketing: Buyer: Marketplace: Menu',
  '/signin': 'Marketing: Buyer: Cash Back: Sign In',
  '/splash-page': 'Marketing: Buyer: Marketplace: Splash Page',
  '/add-card': 'Marketing: Buyer: Cash Back: Add Card',
  '/cards': 'Marketing: Buyer: Cash Back: Card',
  '/success': 'Marketing: Buyer: Cash Back: Confirm: Sign In',
  '/account': 'Marketing: Buyer: Account',
  '/activated-offers': 'Marketing: Buyer: Account: Offers',
  '/earnings-history': 'Marketing: Buyer: Account: Cash Back',
  '/add-email': 'Marketing: Buyer: Cash Back: Add Email',
  '/redeem': 'Marketing: Buyer: Account: Begin Claim',
  '/redeem-confirm': 'Marketing: Buyer: Account: Confirm Claim',
  '/redeem-success': 'Buyer: Account: Claim Confirmed',
  '/how-it-works': 'Marketing: Buyer: Marketplace: Help',
  '/join': 'Marketing: Seller: Join',
  '/cashback': 'Marketing: Buyer: Cash Back',
  '/add-region': 'Marketing: Buyer: Marketplace: Add Region',
  '/region': 'Marketing: Buyer: Marketplace: Select Region',
  '/cash-linking/link': 'Marketing: Buyer: Marketplace: Link with Cash',
  '/cash-linking/loading':
    'Marketing: Buyer: Marketplace: Link with Cash: Loading',
  '/cash-linking/loading-success':
    'Marketing: Buyer: Marketplace: Link with Cash: Success',
  '/cash-linking/loading-error':
    'Marketing: Buyer: Marketplace: Link with Cash: Error',
};

/**
 * Format a page view event for ES2.
 *
 * @param {string} path page path
 * @param {object} params additional fields to log
 * @returns a page view event
 */
export function pageViewEvent(
  path: string,
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE,
  params = {},
  description?: string | null,
): Event {
  const event = {
    page_view_description: description
      ? `${description}: ${region}`
      : `${routeToPageView[path]}: ${region}`,
    ...params,
  };

  return event;
}

/**
 * Format a page click event for ES2.
 *
 * @param {string} action event action, i.e. scan, view, click
 * @param {string} description event description
 * @returns a page click event
 */
export function pageClickEvent(
  action: string,
  description: string,
  params = {},
): Event {
  // trackV2PageClick helper expects these key names
  // https://github.com/squareup/js-eventstream/blob/master/eventstream.js#L95-L96
  const event = { action, description, ...params };

  return event;
}
