export type ApplicationErrorSeverity = 'debug' | 'info' | 'warn' | 'error';

export interface ApplicationErrorProperties {
  action?: string;
  severity?: ApplicationErrorSeverity;
  doNotReport?: boolean;
}

/**
 * Generic error that provides structured properties for more detailed logging.
 */
export default class ApplicationError extends Error {
  action?: string;
  severity: ApplicationErrorSeverity;
  doNotReport?: boolean;

  /**
   *
   * @param {string} message
   * @param {string} props.action Presidio action field
   * @param {string} props.doNotReport suppress reporting of this error
   * @param {string} props.severity debug | info | warn | error
   */
  constructor(message: string, properties: ApplicationErrorProperties = {}) {
    const { action, doNotReport, severity } = {
      severity: 'error' as ApplicationErrorSeverity,
      doNotReport: false,
      ...properties,
    };
    super(message);
    this.action = action;
    this.severity = severity;
    this.doNotReport = doNotReport;
  }
}
