import Location from 'models/location';
import Offer from 'models/offer';

import { CASHBACKER_DOMAIN, getEnvironment, getRuntime } from './environment';
import { snakeCaseKeys } from './snake-case-keys';

export const isObject = (obj: unknown): boolean =>
  typeof obj === 'object' &&
  obj !== null &&
  Object.getPrototypeOf(obj) === Object.prototype;

export const isStringNumeric = (str: string) => {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(Number.parseFloat(str));
};

export const getMerchantUrl = (offer: Offer) => {
  return `/merchants/${offer.locations[0].slug}`;
};

export const isNonEmptyString = (str: string | undefined | null) => {
  if (typeof str === 'string' && str.trim().length > 0) {
    return true;
  } else {
    return false;
  }
};

export function addUrlParams(url: string, urlParams = {}) {
  const snakeCasedParams = snakeCaseKeys(urlParams);
  const paramsArray = Object.entries(snakeCasedParams).filter(([_, value]) =>
    Array.isArray(value) ? value.length > 0 : Boolean(value),
  );

  if (paramsArray.length > 0) {
    const urlParamsStrings = paramsArray.map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(v => `${key}[]=${v}`).join('&');
      } else {
        return `${key}=${value}`;
      }
    });
    return `${url}?${urlParamsStrings.join('&')}`;
  }
  return url;
}

export enum ClientType {
  NodeServer = 'node-server',
  Browser = 'browser',
}

export const buildCashbackerUrl = (resourcePath: string, urlParams = {}) => {
  const runtime = getRuntime();
  const environment = getEnvironment();
  const domain = CASHBACKER_DOMAIN[runtime][environment];
  return addUrlParams(`${domain}/api${resourcePath}`, urlParams);
};

export function filterDistinctLocations(
  location: Location,
  index: number,
  arr: Location[],
) {
  return (
    arr.findIndex((l: Location) => l.address === location.address) === index
  );
}
