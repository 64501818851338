export type Card = {
  brand: string;
  buyerId: string;
  createdAt: string;
  fideliusToken: string;
  id: string;
  paymentAccountReference?: string;
  squareIssuedCard: string;
  status: 'active' | 'inactive' | 'soft_deleted';
  suffix: string;
  updatedAt: string;
};

// Taken from https://prototype.sqprod.co/#/docs/squareup.binfo.api.CardProduct
export const MAP_CARD_BRAND_TO_FRIENDLY_NAME = (brand: string) => {
  switch (brand) {
    case 'INTERNATIONAL_MAESTRO':
      return 'Maestro';
    case 'DINERS_CLUB':
      return 'Diners Club';
    case 'DISCOVER':
      return 'Discover';
    case 'INTERAC':
      return 'Interac';
    case 'JCB':
      return 'JCB';
    case 'MASTERCARD':
      return 'Mastercard';
    case 'VISA':
      return 'Visa';
    case 'AMEX':
      return 'American Express';
    case 'GE_CAPITAL':
      return 'GE Capital';
    case 'UNION_PAY':
      return 'UnionPay';
    case 'FELICA_ID':
      return 'FeliCa';
    case 'FELICA_TRANSPORTATION':
      return 'FeliCa';
    case 'FELICA_QP':
      return 'FeliCa';
    default:
      return brand;
  }
};
