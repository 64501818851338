import { ReactNode } from 'react';
import LocalOffersHeader from './local-offers-header';
import Footer from './v2/footer';

type DefaultLayoutWithFooterProps = {
  children: ReactNode;
};

export default function DefaultLayoutWithFooter({
  children,
}: DefaultLayoutWithFooterProps) {
  return (
    <>
      <LocalOffersHeader />
      {children}
      <Footer />
    </>
  );
}
