import { MarketList, MarketButton, MarketBlade } from '@market/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef } from 'react';

import LocationDropdown from 'components/v2/location-dropdown';

import { useAccount } from 'hooks/use-account';
import { useRegion } from 'hooks/use-region';

import {
  FAQ_URL,
  MENU_URL,
  SHOW_LOCATION_DROPDOWN_ROUTES,
} from 'lib/constants';
import { pageClickEvent, pageViewEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';

import { MarketContextContext } from '../market-context-provider';
import styles from './menu-modal.module.css';

type MenuModalProps = {
  onMarketDialogDismissed: () => void;
};

const MenuModal = ({ onMarketDialogDismissed }: MenuModalProps) => {
  const modal = useRef<HTMLMarketBladeElement | null>(null);
  const marketContextManager = useContext(MarketContextContext)!;
  const { account } = useAccount();
  const router = useRouter();
  const isActiveAccount = account && account.status === 'ACTIVE';
  const { selectedRegion } = useRegion();

  const shouldShowLocationDropdown = () =>
    !isActiveAccount &&
    SHOW_LOCATION_DROPDOWN_ROUTES.includes(router.pathname);

  useEffect(() => {
    const es2Params = {
      page_view_detail: JSON.stringify({
        ...(account && { account: account.id , account_status: account.status}),
      }),
    };
    ES2Tracker.trackPageView(pageViewEvent('/menu', selectedRegion, es2Params));
  }, [account, selectedRegion]);

  useEffect(() => {
    if (marketContextManager.current && modal.current) {
      if (modal.current) {
        marketContextManager.current.open?.(modal.current);
      } else {
        marketContextManager.current.close?.();
      }
    }
  }, [marketContextManager]);

  const closeModal = () => {
    marketContextManager?.current && marketContextManager.current.close();
    onMarketDialogDismissed();
  };

  const trackSignInButtonClickAndCloseModal = () => {
    const es2Params = {
      detail: JSON.stringify({
        category: 'SignIn',
        ...(account && { account_id: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Cash Back: Sign In: ${selectedRegion}`,
        es2Params,
      ),
    );
    closeModal();
  };

  const trackAccountLinkClickAndCloseModal = () => {
    const es2Params = {
      detail: JSON.stringify({
        category: 'Account',
        ...(account && { account_id: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Menu: ${selectedRegion}`,
        es2Params,
      ),
    );
    closeModal();
  };

  const trackCashbackClickAndCloseModal = () => {
    const es2Params = {
      detail: JSON.stringify({
        category: 'Cash Back',
        ...(account && { account_id: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Menu: ${selectedRegion}`,
        es2Params,
      ),
    );
    closeModal();
  };

  const trackActiveOffersClickAndCloseModal = () => {
    const es2Params = {
      detail: JSON.stringify({
        category: 'Active offers',
        ...(account && { account_id: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Menu: ${selectedRegion}`,
        es2Params,
      ),
    );
    closeModal();
  };

  const trackFAQLinkClick = () => {
    const es2Params = {
      detail: JSON.stringify({
        category: 'FAQ',
        ...(account && { account_id: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'link-click',
        `Marketing: Buyer: Marketplace: FAQ: ${selectedRegion}`,
        es2Params,
      ),
    );
  };

  const isCurrentPage = (linkHref: string) => router.asPath === linkHref;

  const MenuContent = () => (
    <main>
      <MarketList className={styles.list}>
        {/* Menu close */}
        <div className={`${styles.row} ${styles.closeButtonRow}`}>
          <div className={`${styles.menuBtn}`} onClick={() => closeModal()}>
            <span />
          </div>
        </div>
        {/* Sign in */}
        {!account && (
          <div className={styles.row}>
            <Link href={MENU_URL.signin}>
              <MarketButton
                onClick={trackSignInButtonClickAndCloseModal}
                data-testid="signinLink"
                className={`${styles.menuButton} ${
                  isCurrentPage(MENU_URL.signin) ? styles.currentPage : ''
                }`}
              >
                <span className={styles.listText}>Sign in</span>
              </MarketButton>
            </Link>
          </div>
        )}
        {/* My profile */}
        {isActiveAccount && (
          <>
            <div className={styles.row}>
              <Link href={MENU_URL.cashback}>
                <MarketButton
                  data-testid="cashbackLink"
                  className={`${styles.menuButton} ${
                    isCurrentPage(MENU_URL.cashback) ? styles.currentPage : ''
                  }`}
                  onClick={trackCashbackClickAndCloseModal}
                >
                  <span className={styles.listText}>Cash back balance</span>
                </MarketButton>
              </Link>
            </div>
            <div className={styles.row}>
              <Link href={MENU_URL.activeOffers}>
                <MarketButton
                  data-testid="activeOffersLink"
                  className={`${styles.menuButton} ${
                    isCurrentPage(MENU_URL.activeOffers)
                      ? styles.currentPage
                      : ''
                  }`}
                  onClick={trackActiveOffersClickAndCloseModal}
                >
                  <span className={styles.listText}>Active offers</span>
                </MarketButton>
              </Link>
            </div>
            <div className={styles.row}>
              <Link href={MENU_URL.account}>
                <MarketButton
                  data-testid="accountLink"
                  className={`${styles.menuButton} ${
                    isCurrentPage(MENU_URL.account) ? styles.currentPage : ''
                  }`}
                  onClick={trackAccountLinkClickAndCloseModal}
                >
                  <span className={styles.listText}>Account</span>
                </MarketButton>
              </Link>
            </div>
          </>
        )}
        {/* FAQ */}
        <div className={styles.row}>
          <Link href={FAQ_URL} target="_blank">
            <MarketButton
              onClick={trackFAQLinkClick}
              data-testid="faqLink"
              className={styles.menuButton}
            >
              <span className={styles.listText}>FAQ</span>
            </MarketButton>
          </Link>
        </div>
        {/* Location dropdown */}
        {shouldShowLocationDropdown() && (
          <div className={styles.row} data-testid="location-dropdown">
            <LocationDropdown data-testid="locationLink" />
          </div>
        )}
      </MarketList>
    </main>
  );

  return (
    <template id="menu-modal" className={styles.menuContainer}>
      <MarketBlade
        onMarketDialogDismissed={onMarketDialogDismissed}
        ref={modal}
        className={styles.menu}
      >
        <MenuContent />
      </MarketBlade>
    </template>
  );
};

export default MenuModal;
