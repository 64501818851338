import environment from 'lib/environment';
import { FlagsService } from 'rpc/model/squareup/feature/relay/flags/service';
import rpc from './rpc';

const path =
  environment === 'production'
    ? 'https://api.squareup.com/1.0/features/get-flags'
    : 'https://api.squareupstaging.com/1.0/features/get-flags';

const flagsClient = FlagsService.create(
  // @ts-ignore
  rpc(() => path),
);

export default flagsClient;
