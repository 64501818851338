import { ResponseCode } from './squareup/protobuf/rpc/rpc';
// Imported for types but not referenced.
// eslint-disable-next-line no-unused-vars
import { Error as SakeError } from './squareup/sake/wire_format';

/**
 * @field {?SakeError} sakeError
 */
export class RpcError extends Error {
  /**
   * @param {Response} response
   * @param {?SakeError} sakeError
   * @param {string=} message
   */
  constructor(response, sakeError, message) {
    super();

    this.message = RpcError.generateMessage(response, sakeError, message);
    this.sakeError = sakeError;
    this.responseStatus = response.status;

    // Work around inability to transpile classes inheriting from native classes.
    Object.setPrototypeOf(this, RpcError.prototype);
  }

  /**
   * @param {Response} response
   * @param {?SakeError} sakeError
   * @param {string=} message
   * @returns {string}
   */
  static generateMessage(response, sakeError, message = 'unknown error') {
    if (!sakeError) {
      return `response failed for ${response.url} (status=${response.status} ${response.statusText}): ${message}`;
    }

    /**
     * @type {string|number}
     */
    let status = response.status;

    if (sakeError.code) {
      status = ResponseCode[sakeError.code];
    }
    if (sakeError.appCode) {
      status = `${status} / app=${sakeError.appCode}`;
    }
    if (sakeError.debugText) {
      message = sakeError.debugText; // eslint-disable-line no-param-reassign
    }

    return `response failed for ${response.url} (sake=${status}): ${message}`;
  }

  /**
   * @param {Response} response
   * @returns {Promise<Response>}
   * @throws {RpcError}
   */
  static fromResponse(response) {
    if (response.status < 400) {
      return new Promise(resolve => resolve(response));
    }

    if (response.status === 401) {
      throw new RpcError(response, SakeError.create({ code: 401 }));
    }

    if (response.headers.get('content-type') === 'application/x-protobuf') {
      return response.arrayBuffer().then(arrayBuffer => {
        let err = null;

        try {
          err = SakeError.decode(new Uint8Array(arrayBuffer));
        } catch (e) {
          // Ignore decode errors
        }

        throw new RpcError(response, err);
      });
    } else {
      return response.text().then(text => {
        throw new RpcError(response, null, text);
      });
    }
  }
}
