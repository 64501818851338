import { useQuery } from '@tanstack/react-query';

import { getBalance } from 'clients/cashbacker-client';

import { Balance } from 'models/balance';

import { GET_BALANCE_KEY } from 'lib/constants';

type UseBalanceOptions = {
  enabled: boolean;
};

export function useBalance(options?: UseBalanceOptions) {
  const { enabled = true } = options || {};

  const { isLoading: isLoadingBalance, data: balance } =
    useQuery<Balance | null>(
      [GET_BALANCE_KEY],
      async () => {
        let balance: Balance | null;
        try {
          const data = await getBalance();
          balance = data.balance;
        } catch {
          balance = null;
        }

        return balance;
      },
      {
        retry: false,
        staleTime: Number.POSITIVE_INFINITY,
        enabled,
      },
    );

  return {
    isLoadingBalance,
    balance,
  };
}
