/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['local-offers-fe']|| ($protobuf.roots['local-offers-fe'] = new $protobuf.Root()));

if (
  !$root?.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.common
    ?.nested?.DataType
) {
$root.addJSON({
  "squareup": {
    "nested": {
      "feature": {
        "nested": {
          "relay": {
            "nested": {
              "common": {
                "nested": {
                  "DataType": {
                    "values": {
                      "NOT_SPECIFIED": 0,
                      "BOOLEAN": 1,
                      "INTEGER": 2,
                      "DOUBLE": 3,
                      "STRING": 4,
                      "JSON": 5
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
});
}

/**
 * SOURCE DataType @ squareup/feature/relay/common/data_type.proto at 9:1
 */
export const DataType = $root.lookupEnum('squareup.feature.relay.common.DataType').values;
