import { createContext, MutableRefObject, ReactNode, useRef } from 'react';

import { MarketContextManager } from '@market/react';

export const MarketContextContext =
  createContext<MutableRefObject<HTMLMarketContextManagerElement | null> | null>(
    null,
  );

export type MarketContextProps = {
  children: ReactNode;
};

/**
 * MarketContext provides access to the MarketContextManager component ref
 * The MarketContextManager provides a way to render dialogs and modals from Market
 *
 * https://github.com/squareup/market/tree/main/web/web-components/src/components/market-context-manager
 *
 * @returns {JSX.Element} MarketContext element
 */
export default function MarketContextProvider({
  children,
}: MarketContextProps) {
  const marketContextElement = useRef<HTMLMarketContextManagerElement | null>(
    null,
  );

  return (
    <>
      <MarketContextContext.Provider value={marketContextElement}>
        {children}
      </MarketContextContext.Provider>
      <MarketContextManager ref={marketContextElement} />
    </>
  );
}
