/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';
import './message/get_flags';

const $root = ($protobuf.roots['local-offers-fe']|| ($protobuf.roots['local-offers-fe'] = new $protobuf.Root()));

if (
  !$root?.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags
    ?.nested?.FlagsService
) {
$root.addJSON({
  "squareup": {
    "nested": {
      "feature": {
        "nested": {
          "relay": {
            "nested": {
              "flags": {
                "nested": {
                  "FlagsService": {
                    "methods": {
                      "GetFlags": {
                        "requestType": "squareup.feature.relay.flags.message.GetFlagsRequest",
                        "responseType": "squareup.feature.relay.flags.message.GetFlagsResponse"
                      },
                      "GetFlagsSandbox": {
                        "requestType": "squareup.feature.relay.flags.message.GetFlagsRequest",
                        "responseType": "squareup.feature.relay.flags.message.GetFlagsResponse"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
});
}

/**
 * SOURCE FlagsService @ squareup/feature/relay/flags/service.proto at 13:1
 */
export const FlagsService = $root.lookupService('squareup.feature.relay.flags.FlagsService');
