import { camelCase } from 'camel-case';
import { isObject } from './utilities';

function recursiveCamelCaseKeys(object: unknown): unknown {
  if (Array.isArray(object)) {
    return object.map(value => recursiveCamelCaseKeys(value));
  } else if (isObject(object)) {
    return Object.fromEntries(
      Object.entries(object as Record<string, unknown>).map(
        ([key, value]): [string, unknown] => {
          return [
            camelCase(key),
            recursiveCamelCaseKeys(value as Record<string, unknown>),
          ];
        },
      ),
    );
  } else {
    return object;
  }
}

export function camelCaseKeys(
  object: Record<string, unknown> | Array<Record<string, unknown>>,
) {
  return recursiveCamelCaseKeys(object) as
    | Record<string, unknown>
    | Array<Record<string, unknown>>;
}
