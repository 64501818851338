export const REQUEST_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
} as const;

export type RequestMethod = typeof REQUEST_METHOD[keyof typeof REQUEST_METHOD];

// From styleguide: https://plathome.sqprod.co/styleguide/guidance/search-resource-action
export type SearchQuery = {
  filter?: {
    [filterName: string]: any;
  };
  sort?: {
    field: string;
    order: string;
  };
  cursor?: string;
};
