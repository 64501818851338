/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../Fixes';
import Builder from '../../../../Builder';

const $root = ($protobuf.roots['local-offers-fe']|| ($protobuf.roots['local-offers-fe'] = new $protobuf.Root()));

if (
  !$root?.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.common
    ?.nested?.Attribute
) {
$root.addJSON({
  "squareup": {
    "nested": {
      "feature": {
        "nested": {
          "relay": {
            "nested": {
              "common": {
                "nested": {
                  "Attribute": {
                    "fields": {
                      "name": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "stringValue": {
                        "type": "string",
                        "id": 2
                      },
                      "intValue": {
                        "type": "int32",
                        "id": 3
                      },
                      "boolValue": {
                        "type": "bool",
                        "id": 4
                      },
                      "doubleValue": {
                        "type": "double",
                        "id": 5
                      }
                    },
                    "nested": {
                      "value": {
                        "oneof": [
                          "stringValue",
                          "intValue",
                          "boolValue",
                          "doubleValue"
                        ]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
});
}

/**
 * SOURCE Attribute @ squareup/feature/relay/common/attribute.proto at 10:1
 */
export const Attribute = $root.lookupType('squareup.feature.relay.common.Attribute');
fixType(Attribute, {
  name: { required: true, notEmpty: true },
});
Builder.createAndAttachToType(Attribute);
