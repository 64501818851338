import { MarketHeader } from '@market/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import Header from 'components/header';
import styles from 'components/local-offers-header.module.css';

import { useAccount } from 'hooks/use-account';
import { useBalance } from 'hooks/use-balance';
import { useRegion } from 'hooks/use-region';

import { pageClickEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';
import { formatMoney } from 'lib/formatters';

import HowItWorksModal from './v2/how-it-works-modal';
import MenuModal from './v2/menu-modal';

const LocalOffersHeader = ({ hideMenu }: { hideMenu?: boolean }) => {
  const { account, isLoading: isAccountLoading } = useAccount();
  const { balance } = useBalance({ enabled: Boolean(account) });
  const { selectedRegion } = useRegion();
  const isActiveAccount = account && account.status === 'ACTIVE';

  const router = useRouter();
  const showNormalHeader = !router.pathname?.includes('/merchants');

  const [showHowItWorksModal, setShowHowItWorksModal] =
    useState<boolean>(false);

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const openMenu = () => {
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'link-click',
        `Marketing: Buyer: Marketplace: Menu: ${selectedRegion}`,
      ),
    );
    setShowMenu(true);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const trackSquareLogoClick = () => {
    const es2Params = {
      detail: JSON.stringify({
        method: 'Header',
        ...(account && { account: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Cash Back: Home: ${selectedRegion}`,
        es2Params,
      ),
    );
  };

  const trackCashbackLinkClick = () => {
    const es2Params = {
      detail: JSON.stringify({
        method: 'Header',
        ...(account && { account: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Cash Back: Cashback: ${selectedRegion}`,
        es2Params,
      ),
    );
  };

  const trackSignInButtonClick = () => {
    const es2Params = {
      detail: JSON.stringify({
        method: 'MarketPlace',
        ...(account && { account: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Cash Back: Sign In: ${selectedRegion}`,
        es2Params,
      ),
    );
  };

  return (
    <div
      className={`${styles.headerContainer} ${!hideMenu && styles.hasBorder}`}
    >
      <div className={styles.headerWrapper}>
        {showNormalHeader && <Header />}
        <MarketHeader compact={false}>
          <div className={styles.header}>
            {/* Square Logo Desktop */}
            <Link
              href="/"
              className={styles.desktopSquareLogo}
              onClick={trackSquareLogoClick}
              data-testid="square-logo-desktop"
            >
              <img
                src="/square-local-offers-logo.svg"
                alt="Square Local Offers Logo"
                width={161}
                height={42}
              />
            </Link>
            <Link
              href="/"
              className={styles.mobileSquareLogo}
              onClick={trackSquareLogoClick}
              data-testid="square-logo-mobile"
            >
              {hideMenu && (
                <img
                  src="/square-local-offers-logo.svg"
                  alt="Square Local Offers Logo"
                  width={161}
                  height={42}
                />
              )}
            </Link>
            {/* Cashback balance or Sign In button */}
            {!hideMenu && (
              <div className={styles.headerItems}>
                {isActiveAccount && balance && (
                  <Link
                    href="/cashback"
                    onClick={trackCashbackLinkClick}
                    className={styles.cashbackBalance}
                    data-testid="cashback-balance"
                  >
                    {formatMoney(balance.current)}
                  </Link>
                )}
                {!isAccountLoading && !isActiveAccount && (
                  <Link
                    href="/signin"
                    onClick={trackSignInButtonClick}
                    className={styles.cashbackBalance}
                    data-testid="signinLink"
                  >
                    <span className={styles.listText}>Sign in</span>
                  </Link>
                )}
                {/* Square Logo Mobile */}
                <Link
                  href="/"
                  className={styles.mobileSquareLogo}
                  onClick={trackSquareLogoClick}
                  data-testid="square-logo-mobile"
                >
                  <img
                    src="/icons/square-logo.svg"
                    alt="Square Logo"
                    width={88}
                    height={22}
                  />
                </Link>
                {/* Menu */}
                <div
                  className={`${styles.menuBtn} ${
                    showMenu ? styles.active : ''
                  }`}
                  onClick={openMenu}
                >
                  <span />
                </div>
              </div>
            )}
          </div>
        </MarketHeader>
        {showHowItWorksModal && (
          <HowItWorksModal
            onMarketDialogDismissed={() => setShowHowItWorksModal(false)}
          />
        )}
        {showMenu && <MenuModal onMarketDialogDismissed={closeMenu} />}
      </div>
    </div>
  );
};

export default LocalOffersHeader;
