import { Account } from 'models/account';
import { Activity } from 'models/activity';
import { Balance } from 'models/balance';
import { Banner } from 'models/banner';
import { Card, MAP_CARD_BRAND_TO_FRIENDLY_NAME } from 'models/card';
import Category from 'models/category';
import { Earning } from 'models/earning';
import Offer from 'models/offer';
import { Redemption } from 'models/redemption';

import { camelCaseKeys } from 'lib/camel-case-keys';
import { ValidRegion, REGION_NOT_AVAILABLE_TYPE } from 'lib/constants';
import errorReporter from 'lib/error-reporters/unified-error-reporter';
import FetchError from 'lib/errors/fetch-error';
import { snakeCaseKeys } from 'lib/snake-case-keys';
import { buildCashbackerUrl } from 'lib/utilities';

import { REQUEST_METHOD, SearchQuery } from './types';

type ResponseJSONError = {
  category: 'string';
  code: 'string';
  detail: 'string';
};

type ResponseJSON = {
  errors?: Array<ResponseJSONError>;
  [key: string]: unknown;
};

export type LinkWithCashUrls = {
  redirectUrl: string;
  qrCodeImageUrl: string;
};

const identityFn = (obj: any) => obj;

// OFFERS
export function activateOffer(offerId: string): Promise<{ offer: Offer }> {
  const url = buildCashbackerUrl(`/offers/${offerId}/activate`);

  return _fetchWithCsrf<{ offer: Offer }>(url, {
    method: REQUEST_METHOD.POST,
  });
}

export async function searchOffers(
  query: SearchQuery,
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE,
): Promise<{ offers: Array<Offer> }> {
  // The /api/offers/search follows the styleguide here: https://plathome.sqprod.co/styleguide/guidance/search-resource-action
  const url = buildCashbackerUrl('/offers/search');
  return _fetchWithCsrf<{ offers: Array<Offer> }>(url, {
    method: REQUEST_METHOD.POST,
    body: JSON.stringify({
      query,
      region,
    }),
  });
}

// ACCOUNT
export function getAccount(urlParams?: {
  include?: string[];
}): Promise<{ account: Account }> {
  const url = buildCashbackerUrl('/accounts/me', urlParams);

  return _fetchWithCsrf<{ account: Account }>(url, {
    headers: {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
  });
}

export function updateAccountEmail(
  email: string,
): Promise<{ account: Account }> {
  const url = buildCashbackerUrl('/accounts/me');
  return _fetchWithCsrf<{ account: Account }>(url, {
    method: REQUEST_METHOD.PUT,
    body: JSON.stringify({
      account: snakeCaseKeys({
        emailAddress: email,
      }),
    }),
  });
}

export function updateAccountRegion(
  accountRegion: ValidRegion | REGION_NOT_AVAILABLE_TYPE,
): Promise<{ account: Account }> {
  const url = buildCashbackerUrl('/accounts/me');
  return _fetchWithCsrf<{ account: Account }>(url, {
    method: REQUEST_METHOD.PUT,
    body: JSON.stringify({
      account: snakeCaseKeys({
        region: accountRegion,
      }),
    }),
  });
}

export function activateAccount(): Promise<{ account: Account }> {
  const url = buildCashbackerUrl('/accounts/me/activate');
  return _fetchWithCsrf<{ account: Account }>(url, {
    method: REQUEST_METHOD.POST,
  });
}

// CARDS

export async function getCards(): Promise<{ cards: Array<Card> }> {
  const url = buildCashbackerUrl('/cards');
  return _fetchWithCsrf<{ cards: Array<Card> }>(url, {}, data => {
    return {
      cards: data.cards.map((card: Card) => ({
        ...card,
        brand: MAP_CARD_BRAND_TO_FRIENDLY_NAME(card.brand),
      })),
    };
  });
}

export function createCard(
  cardNonce: string,
  idempotencyKey: string,
): Promise<{ card: Card }> {
  const url = buildCashbackerUrl('/cards');
  return _fetchWithCsrf<{ card: Card }>(url, {
    method: REQUEST_METHOD.POST,
    body: JSON.stringify(
      snakeCaseKeys({
        cardNonce,
        idempotencyKey,
      }),
    ),
  });
}

export function importCard(cardId: string): Promise<{ card: Card }> {
  const url = buildCashbackerUrl('/cards/import');
  return _fetchWithCsrf<{ card: Card }>(url, {
    method: REQUEST_METHOD.POST,
    body: JSON.stringify(
      snakeCaseKeys({
        cardId,
      }),
    ),
  });
}

export function deleteCard(cardId: string): Promise<{ card: Card }> {
  const url = buildCashbackerUrl(`/cards/${cardId}`);
  return _fetchWithCsrf<{ card: Card }>(url, {
    method: REQUEST_METHOD.DELETE,
  });
}

// EARNINGS

export function getEarnings({
  cursor,
  limit,
}: {
  cursor: string | null;
  limit: number;
}): Promise<{ earnings: Array<Earning> }> {
  const url = buildCashbackerUrl('/earnings', {
    cursor,
    limit,
  });
  return _fetchWithCsrf<{ earnings: Array<Earning> }>(url);
}

export async function getRedemptions(): Promise<{
  redemptions: Array<Redemption>;
}> {
  const url = buildCashbackerUrl('/redemptions');
  return _fetchWithCsrf<{ redemptions: Array<Redemption> }>(url);
}

export function createRedemption(
  cashtag: string | null,
): Promise<{ redemption: Redemption }> {
  const url = buildCashbackerUrl('/redemptions');
  return _fetchWithCsrf<{ redemption: Redemption }>(url, {
    method: REQUEST_METHOD.POST,
    body:
      cashtag &&
      JSON.stringify(
        snakeCaseKeys({
          cashtag,
        }),
      ),
  });
}

export async function getCategories(): Promise<{
  categories: Array<Category>;
}> {
  const url = buildCashbackerUrl('/categories');
  return _fetchWithCsrf<{ categories: Array<Category> }>(url);
}

export function getActivities(urlParams: {
  month?: number;
  year?: number;
}): Promise<{ activities: Array<Activity> }> {
  const url = buildCashbackerUrl('/activities', urlParams);

  return _fetchWithCsrf<{ activities: Array<Activity> }>(url);
}

export function linkWithCash(): Promise<LinkWithCashUrls> {
  const url = buildCashbackerUrl('/accounts/me/link-cash-account');
  return _fetchWithCsrf<{ redirectUrl: string; qrCodeImageUrl: string }>(url, {
    method: REQUEST_METHOD.POST,
  });
}

// BANNERS

export async function getBanners(): Promise<{ banners: Array<Banner> }> {
  const url = buildCashbackerUrl('/banners');
  return _fetchWithCsrf<{ banners: Array<Banner> }>(url);
}

// BALANCE

export async function getBalance(): Promise<{ balance: Balance }> {
  const url = buildCashbackerUrl('/balance');
  return _fetchWithCsrf<{ balance: Balance }>(url);
}

/**
 * Private functions
 */

async function _fetchWithCsrf<T>(
  url: string,
  init: RequestInit = {},
  transformFn: (obj: any) => any = identityFn,
) {
  const csrfToken = _readCookie('_js_csrf[square-buyer]');

  const method = init.method || REQUEST_METHOD.GET;
  const response = await fetch(url, {
    ...init,
    method,
    headers: {
      ...init?.headers,
      'x-csrf-token': csrfToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    } as HeadersInit,
  });

  if (!response.ok && !skipErrorReporting(method as string, url, response)) {
    const message = await response.text();
    const error = new FetchError(
      `Failed calling ${init.method} ${url} from browser. Response: ${message}`,
      {
        url,
        status: response.status,
      },
    );
    errorReporter.reportError(error);
    throw error;
  }

  const data: ResponseJSON = await response.json();

  return transformFn(camelCaseKeys(data)) as T;
}

function _readCookie(name: string) {
  if (typeof document === 'undefined') return null;

  const cookieNameString = `${name}=`;
  const cookieStrings = document.cookie.split(';');
  for (let cookieString of cookieStrings) {
    while (cookieString.charAt(0) === ' ')
      cookieString = cookieString.slice(1, cookieString.length);
    if (cookieString.indexOf(cookieNameString) === 0)
      return cookieString.slice(cookieNameString.length, cookieString.length);
  }
  return null;
}

function skipErrorReporting(method: string, _url: string, response: Response) {
  return method === REQUEST_METHOD.GET && response.status === 401;
}
