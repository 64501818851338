/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../../../Fixes';
import Builder from '../../../../../Builder';

const $root = ($protobuf.roots['local-offers-fe']|| ($protobuf.roots['local-offers-fe'] = new $protobuf.Root()));

if (
  !$root?.nested?.squareup?.nested?.feature?.nested?.relay?.nested?.flags
    ?.nested?.message?.nested?.LaunchDarklyProject
) {
$root.addJSON({
  "squareup": {
    "nested": {
      "feature": {
        "nested": {
          "relay": {
            "nested": {
              "flags": {
                "nested": {
                  "message": {
                    "nested": {
                      "LaunchDarklyProject": {
                        "values": {
                          "NOT_SUPPLIED": 0,
                          "PIE": 1,
                          "CAPITAL": 2,
                          "SQUARE_CONSOLE": 3
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
});
}

/**
 * SOURCE LaunchDarklyProject @ squareup/feature/relay/flags/message/launch_darkly_project.proto at 8:1
 */
export const LaunchDarklyProject = $root.lookupEnum('squareup.feature.relay.flags.message.LaunchDarklyProject').values;
