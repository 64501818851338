import Cookies from 'js-cookie';

import { Banner } from 'models/banner';

import { DISMISSED_BANNERS, REGION_COOKIE, ValidRegion } from 'lib/constants';

export const setCookie = async ({
  name,
  value,
  days = 30,
}: {
  name: string;
  value: string;
  days: number;
}) => {
  await Cookies.set(name, value, { expires: days });
};

export const setRegionCookie = (value: string) => {
  setCookie({ name: REGION_COOKIE, value, days: 30 });
};

export const getDismissedBannerIdsCookie = () => {
  return Cookies.get(DISMISSED_BANNERS)?.split(',') || [];
};

export const setDismissedBannerIdsCookie = (banner: Banner) => {
  const dismissedBanners = Cookies.get(DISMISSED_BANNERS)?.split(',');
  const value = dismissedBanners
    ? [...new Set([banner.id, ...dismissedBanners])].join(',')
    : banner.id;

  setCookie({ name: DISMISSED_BANNERS, value, days: 1 });
};

export const getRegionCookie = () => {
  const unsanitizedRegionCookie = Cookies.get(REGION_COOKIE);
  let sanitizedRegionCookie = null;
  if (
    unsanitizedRegionCookie &&
    Object.keys(ValidRegion).includes(unsanitizedRegionCookie)
  ) {
    sanitizedRegionCookie = unsanitizedRegionCookie;
    return sanitizedRegionCookie as ValidRegion;
  } else {
    return null;
  }
};
