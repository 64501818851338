import EventstreamClient from 'eventstream.js/dist/eventstream';
import { v4 as uuidv4 } from 'uuid';
import { getEnvironment } from 'lib/environment';

export class EventTracker {
  public client: {
    trackV2PageClick: (data?: Record<string, unknown>) => void;
    trackV2PageView: (data?: Record<string, unknown>) => void;
    trackV2WithDefaults: (data?: Record<string, unknown>) => void;
  };
  public anonymousId?: string;
  public merchantId?: string;

  constructor() {
    this.client = new EventstreamClient({
      applicationName: 'local-offers-fe',
      environment: getEnvironment(),
    });

    // A unique token for anonymous visitors.
    // One of anonymousId and userId are required at the time events are sent.
    // https://github.com/squareup/js-eventstream
    this.anonymousId = uuidv4();
  }

  trackPageView(data?: Record<string, unknown>): void {
    this.client.trackV2PageView({
      anonymousId: this.anonymousId,
      ...data,
    });
  }

  trackPageClick(data?: Record<string, unknown>): void {
    this.client.trackV2PageClick({
      anonymousId: this.anonymousId,
      ...data,
    });
  }
}

const tracker = new EventTracker();

export default tracker;
