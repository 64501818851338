import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary } from '@sentry/nextjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { ReactFragment, ReactPortal, useEffect, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/footer.css';
import 'styles/globals.css';
import 'styles/google-reviews.css';
import 'styles/home.css';
import 'styles/how-it-works.css';
import 'styles/offer-card.css';
import 'styles/offer-modal.css';
import 'styles/place-logo.css';
import 'styles/splash-page.css';

import MarketContextProvider from '../components/market-context-provider';
import '@market/web-components/dist/market/market.css';
import DefaultLayout from 'components/default-layout';

import environment from 'lib/environment';
import ConsoleErrorReporter from 'lib/error-reporters/console-error-reporter';
import SentryErrorReporter from 'lib/error-reporters/sentry-error-reporter';
import errorReporter from 'lib/error-reporters/unified-error-reporter';
import { PixelTracker } from 'lib/facebook-pixel-tracker';
import { GoogleTracker } from 'lib/google-ads-tracker';

errorReporter.addReporter(SentryErrorReporter);
errorReporter.addReporter(ConsoleErrorReporter);

type Page<P = unknown> = NextPage<P> & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type Props = AppProps & {
  Component: Page;
};

/**
 * https://github.com/squareup/market/blob/main/web/web-components/docs/getting-started/react.md
 */
async function prepareMarketComponents() {
  const { applyPolyfills, defineCustomElements } = await import(
    '@market/web-components/loader'
  );
  await applyPolyfills();
  defineCustomElements(window);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function getDefaultLayout(page: ReactNode | ReactFragment | ReactPortal) {
  return <DefaultLayout>{page}</DefaultLayout>;
}

// TODO: Improve setup in https://block.atlassian.net/browse/CEMA-972
datadogRum.init({
  applicationId: '292fdf14-ba4d-4134-852b-62b2a1e607df',
  clientToken: 'pubb4ebfb62e453bfe4b699b7d07f30d171',
  site: 'datadoghq.com',
  service: 'square-local-offers',
  env: environment,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const MyApp = ({ Component, pageProps }: Props) => {
  useEffect(() => {
    prepareMarketComponents();
    PixelTracker.trackPageView();
    GoogleTracker.trackPageView();
    GoogleTracker.trackPageViewTest();
  }, []);

  const getLayout = Component.getLayout || getDefaultLayout;

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <MarketContextProvider>
          {getLayout(<Component {...pageProps} />)}
        </MarketContextProvider>
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default MyApp;
