import ApplicationError from 'lib/errors/application-error';

export interface UnifiedErrorReporterFunction {
  (err: Error): void;
}

const registeredReporters: Set<UnifiedErrorReporterFunction> = new Set();

/**
 * Pass an error along to any error reporters that have been registered with
 * addReporter.
 *
 * @param {Error} err
 */
const reportError = (err: Error): void => {
  if ('doNotReport' in err) {
    if ((err as ApplicationError).doNotReport) {
      return;
    }
  }

  for (const reporter of registeredReporters) {
    try {
      reporter(err);
    } catch {
      /* Nothing can be done here without risking a stack overflow */
    }
  }
};

/**
 * Add an error reporter to the list of active error reporters.
 *
 * @param {UnifiedErrorReporterFunction} reporter
 */
const addReporter = (reporter: UnifiedErrorReporterFunction): void => {
  registeredReporters.add(reporter);
};

const errorReporter = {
  reportError,
  addReporter,
};

export default errorReporter;
