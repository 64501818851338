import environment, {
  loginDomains,
  domains,
  squareupDomains,
  squareProfileDomains,
  cashAppDomains,
} from './environment';

/**
 * Square URLs
 */
export const LOGIN_URL = `${loginDomains[environment]}/login?return_to=${domains[environment]}/signin`;
export const LOGOUT_URL = `${loginDomains[environment]}/logout?return_to=${domains[environment]}`;
export const TERMS_URL = `${squareupDomains[environment]}/legal/general/square-local-buyer-terms`;
export const SQ_PAY_TERMS_URL = `${squareupDomains[environment]}/legal/general/square-pay-tos`;
export const FEEDBACK_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdgIrEnrdT8-yuIjWGuqrWS_XiGoDNOvudIG85SaGvRVfpEZg/viewform';
export const PRIVACY_URL = 'https://squareup.com/us/en/legal/general/privacy';
export const BUYER_PRIVACY_URL =
  'https://squareup.com/us/en/legal/general/buyer-features';
export const FAQ_URL =
  'https://squareup.com/help/us/en/article/7933-square-local-faq';
export const SQ_PROFILE_URL = `${squareProfileDomains[environment]}`;

/**
 * Cash APP URLs
 */
export const CASH_APP_URL = `${cashAppDomains[environment]}/banking/cash-card/style-picker`;

/**
 * Error messages
 */
export const DUPLICATE_CARD_ERROR_MESSAGE =
  'Your card has been saved to your Square Pay account, however this card is already enrolled in a Square Local Offers program, please add a different card to create your account.';
export const GENERIC_CARD_ERROR_MESSAGE =
  'Something went wrong. Please review your card information or try again later.';
export const GENERIC_ERROR_MESSAGE =
  'Something went wrong. Please try again later.';
export const INVALID_EMAIL = 'Invalid email address';
export const INVALID_CARD = 'Invalid card';
export const INVALID_OFFER_ERROR_MESSAGE =
  'This offer no longer exists. Please search active offers below';
/**
 * Query params
 */
export const QUERY_PARAM_SELECTED_MERCHANT_SLUG = 'selectedmerchantslug';
export const QUERY_PARAM_REGION = 'region';

/**
 * Query keys for react-query
 */
export const GET_ACTIVITIES_QUERY_KEY = 'getActivities';
export const GET_OFFERS_QUERY_KEY = 'getOffers';
export const GET_EARNINGS_QUERY_KEY = 'getEarnings';
export const GET_CARDS_QUERY_KEY = 'getCards';
export const GET_ACCOUNT_QUERY_KEY = 'getAccount';
export const GET_REDEMPTIONS_QUERY_KEY = 'getRedemptions';
export const GET_CATEGORIES_QUERY_KEY = 'getCategories';
export const GET_BANNERS_QUERY_KEY = 'getBanners';
export const GET_SELECTED_REGION_QUERY_KEY = 'getRegion';
export const GET_GEOLOCATION_QUERY_KEY = 'getGeolocation';
export const GET_FLAGS = 'getFlags';
export const GET_BONUS_GRANTS = 'getBonusGrants';
export const GET_BALANCE_KEY = 'getBalance';

/**
 * Social Media URLs
 */
export const SQ_FACEBOOK_URL = 'https://www.facebook.com/square';
export const SQ_INSTAGRAM_URL = 'https://www.instagram.com/square';
export const SQ_TWITTER_URL = 'https://twitter.com/Square';

/**
 * Earnings Limit
 */
export const LIMIT_FOR_EARNING_PREVIEW = 6;
export const LIMIT_FOR_EARNING_PAGE = 1000;

/**
 * Form URLs
 */
export const SELLER_JOIN_FORM_URL = 'https://forms.gle/CkEMEekMKSkWxhwR8';
export const REQUEST_SELLER_FORM_URL = 'https://forms.gle/7D6EK4D5thR7XUJY6';
export const FAQ_PAGE_URL =
  'https://squareup.com/help/us/en/article/7933-square-local-faq';

/**
 * available region
 * whitelisted in multipass: https://github.com/squareup/java/blob/25b84ff362b4c6b8650df708db2bb630d630a172/multipass/client/src/main/java/com/squareup/multipass/client/webservice/SessionCookieBuilder.java#L63-L87
 */
export const REGION_COOKIE = 'sq_local_buyer_region';
export const DISMISSED_BANNERS = 'sq_local_dismissed_banners';

export enum ValidRegion {
  portland = 'portland',
  atlanta = 'atlanta',
}

export const REGION_NOT_AVAILABLE = 'region_not_available' as const;
export type REGION_NOT_AVAILABLE_TYPE = typeof REGION_NOT_AVAILABLE;

export const REGION_LIST = [ValidRegion.portland, ValidRegion.atlanta];

export const REGION_NAME_MAP = {
  [ValidRegion.portland]: 'Portland, OR',
  [ValidRegion.atlanta]: 'Atlanta, GA',
};

export const SHOW_LOCATION_DROPDOWN_ROUTES = ['/', '/map'];

export const merchantCountPerRegion = {
  portland: 100,
  atlanta: 20,
};

/**
 * Geolocation center
 */

// Center of cities
export const DEFAULT_CENTER = {
  [ValidRegion.portland]: {
    lat: 45.520324,
    lng: -122.680771,
  },
  [ValidRegion.atlanta]: {
    lat: 33.74875144922911,
    lng: -84.38768223124481,
  },
  // defaults to Portland Lat Lng
  [REGION_NOT_AVAILABLE]: {
    lat: 45.520324,
    lng: -122.680771,
  },
};

/**
 * Menu Href
 */
export const MENU_URL = {
  signin: '/signin',
  cashback: '/cashback',
  activeOffers: '/activated-offers',
  account: '/account',
};

export const enum Brands {
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  DISCOVER_DINERS = 'DISCOVER_DINERS',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  CHINA_UNIONPAY = 'CHINA_UNIONPAY',
  VISA = 'VISA',
  SQUARE_CASH_APP_CARD = 'SQUARE_CASH_APP_CARD',
  UNKNOWN = 'UNKNOWN',
}

export const enum SquareIssuedCards {
  UNKNOWN_OR_NOT_SQUARE_ISSUED = 'UNKNOWN_OR_NOT_SQUARE_ISSUED',
  CASH_CARD = 'CASH_CARD',
  SQUARE_CARD = 'SQUARE_CARD',
  AFTERPAY_CARD = 'AFTERPAY_CARD',
}

export const cardBrands = {
  [Brands.AMERICAN_EXPRESS]: {
    src: 'american_express_front',
    alt: 'American Express',
    testId: 'amex-card-icon',
  },
  [Brands.DISCOVER_DINERS]: {
    src: 'discover_diners_front',
    alt: 'Discover Diners',
    testId: 'diners-card-icon',
  },
  [Brands.DISCOVER]: {
    src: 'discover_front',
    alt: 'Discover',
    testId: 'discover-card-icon',
  },
  [Brands.JCB]: {
    src: 'jcb_front',
    alt: 'JCB',
    testId: 'jcb-card-icon',
  },
  [Brands.MASTERCARD]: {
    src: 'master_card_front',
    alt: 'MasterCard',
    testId: 'master-card-icon',
  },
  [Brands.CHINA_UNIONPAY]: {
    src: 'union_pay_front',
    alt: 'Union Pay',
    testId: 'union-pay-card-icon',
  },
  [Brands.VISA]: {
    src: 'visa_front',
    alt: 'Visa',
    testId: 'visa-card-icon',
  },
  [Brands.SQUARE_CASH_APP_CARD]: {
    src: 'cash_app_front',
    alt: 'Cash App Card',
    testId: 'square-cash-app-icon',
  },
  [Brands.UNKNOWN]: {
    src: 'unknown_front',
    alt: 'Unknown Card',
    testId: 'unknown-card-icon',
  },
};

/**
 * Bonus Types
 * Values matches their respective model names in cashbacker
 */

export enum BonusTypes {
  WinbackInactiveUserBonus = 'WinbackInactiveUserBonus',
  WinbackInactiveEarnerBonus = 'WinbackInactiveEarnerBonus',
  TransactionBonus = 'TransactionBonus',
  CashAppCardBonus = 'CashAppCardBonus',
  FirstPurchaseBonus = 'FirstPurchaseBonus',
  CashAppPayBonus = 'CashAppPayBonus',
  // SquarePayWelcomeBonus = 'SquarePayWelcomeBonus',
}

/**
 * Session Info
 */

export const SQUARE_BUYER_SESSION_COOKIE_NAME = '_square_buyer_session';
export const BUYER_ID_HEADER_NAME = 'buyer_id';

/**
 * Default currency code
 */

export const DEFAULT_CURRENCY_CODE = 'USD';
