import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MarketButton,
  MarketDivider,
  MarketHeader,
  MarketModalPartial,
} from '@market/react';
import React, { useContext, useEffect, useRef } from 'react';

import { DialogDismissedEvent } from '@market/web-components/dist/types/utils/dialog';

import { useAccount } from 'hooks/use-account';
import { useRegion } from 'hooks/use-region';

import { FAQ_PAGE_URL } from 'lib/constants';
import { pageClickEvent, pageViewEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';

import { MarketContextContext } from '../market-context-provider';
import styles from './how-it-works-modal.module.css';

type HowItWorksModalProps = {
  onMarketDialogDismissed: (event: CustomEvent<DialogDismissedEvent>) => void;
};

const HowItWorksModal = ({ onMarketDialogDismissed }: HowItWorksModalProps) => {
  const modal = useRef<HTMLMarketModalPartialElement | null>(null);
  const marketContextManager = useContext(MarketContextContext)!;
  const { selectedRegion } = useRegion();
  const { account } = useAccount();

  useEffect(() => {
    const es2Params = {
      page_view_detail: JSON.stringify({
        ...(account && { account: account.id, account_status: account.status }),
      }),
    };
    ES2Tracker.trackPageView(
      pageViewEvent('/how-it-works', selectedRegion, es2Params),
    );
  }, [account, selectedRegion]);

  useEffect(() => {
    if (marketContextManager.current && modal.current) {
      if (modal.current) {
        marketContextManager.current.open?.(modal.current);
      } else {
        marketContextManager.current.close?.();
      }
    }
  }, [marketContextManager]);

  const trackSignInButtonClick = () => {
    const es2Params = {
      detail: 'MarketPlace',
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Cash Back: Sign In: ${selectedRegion}`,
        es2Params,
      ),
    );
  };

  const trackBuyerFaqClick = () => {
    const es2Params = {
      detail: 'buyer',
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: FAQ: ${selectedRegion}`,
        es2Params,
      ),
    );
  };

  return (
    <template id="how-it-works-modal">
      <MarketModalPartial
        onMarketDialogDismissed={onMarketDialogDismissed}
        ref={modal}
      >
        <MarketHeader>
          <h1 className="display-20">How it works</h1>
        </MarketHeader>
        <main>
          <div className={styles.item}>
            <h2 className={styles.title}>Sign up</h2>
            <p className={styles.description}>
              Enroll in the cash back program by logging into your Square
              Profile and adding your credit card.
            </p>
            <div className={styles.image}>
              <img
                src="/images/how-it-works-sign-up.png"
                width={150}
                height={112}
                alt="Sign up"
              />
            </div>
          </div>
          <div className={styles.item}>
            <h2 className={styles.title}>Activate</h2>
            <p className={styles.description}>
              Browse and activate offers from your favorite participating brands
              and local businesses.
            </p>
            <div className={styles.image}>
              <img
                src="/images/how-it-works-activate.png"
                width={150}
                height={112}
                alt="Activate"
              />
            </div>
          </div>
          <div className={styles.item}>
            <h2 className={styles.title}>Shop</h2>
            <p className={styles.description}>
              Upon purchase, you’ll automatically earn cash back on activated
              offers.
            </p>
            <div className={styles.image}>
              <img
                src="/images/how-it-works-shop.png"
                width={150}
                height={128}
                alt="Shop"
              />
            </div>
          </div>
          <div className={styles.item}>
            <h2 className={styles.title}>Get paid</h2>
            <p className={styles.description}>
              Accumulate cash back on purchases which can be redeemed using Cash
              App.
            </p>
            <div className={styles.image}>
              <img
                src="/images/how-it-works-get-paid.png"
                width={150}
                height={128}
                alt="Get paid"
              />
            </div>
          </div>
          {!account && (
            <MarketButton
              className={styles.button}
              rank="primary"
              href="/signin"
              onClick={trackSignInButtonClick}
            >
              Sign in
            </MarketButton>
          )}

          <MarketButton
            className={styles.faqCta}
            onClick={trackBuyerFaqClick}
            href={FAQ_PAGE_URL}
            target="_blank"
            rank="secondary"
            size="medium"
          >
            <span slot="icon">
              <FontAwesomeIcon
                data-testid="link icon"
                icon={faArrowUpRightFromSquare}
                width={18}
                height={18}
              />
            </span>
            <span>Frequently asked questions</span>
          </MarketButton>
          <MarketDivider className={styles.divider} />

          <div className={styles.disclaimer}>
            <p className="paragraph-10">
              <strong>Details</strong>
            </p>
            <p className="paragraph-10">
              All cash back offers will expire after a maximum of 7 days.
              Certain cash back offers on the Website may change at any time,
              without notice. Cash back offers may expire or be canceled at any
              time. Cash back offers are not transferable. Cash back offers are
              valid in-store and/or via Square Online, if applicable. You will
              not receive cash back rewards if the cash back offer has expired
              or otherwise terminated before you make the eligible purchase.
            </p>
            <p className="paragraph-10">
              To receive cash back on the offer, you must make an eligible
              purchase, using the credit card that you selected in Square
              Profile, at the Square Seller whose offer you have activated. To
              redeem your cashback rewards, you must have or create a Cash App
              account and transfer your Rewards to that account.
            </p>
            <p className="paragraph-10">
              Your request to transfer funds to Cash App may take up to 7 days
              to process. If you do not own a Cash App account, you must create
              one in order to redeem your cash back rewards. If your Cash App
              account is terminated for any reason, you will not be able to
              redeem your cash back rewards for cash unless and until your
              access to your Cash App account is restored. You must be the owner
              of the Cash App account to which you transfer cash back rewards.
            </p>
          </div>
        </main>
      </MarketModalPartial>
    </template>
  );
};

export default HowItWorksModal;
