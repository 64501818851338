import environment from 'lib/environment';

export const GA_SQUAREUP_TRACKING_ACCOUNT = 'GTM-P72T5M5';
export const GA_TRACKING_ACCOUNT = 'AW-11218848576';
// Tracking id for Pageviews
export const GA_PAGE_VIEW_TRACKING_ID = `${GA_TRACKING_ACCOUNT}/gKCCCPHir6wYEMCWyOUp`;
// Tracking id for CompleteRegistration
export const GA_COMPLETE_REGISTRATION_TRACKING_ID = `${GA_TRACKING_ACCOUNT}/rOS_CJ7JrawYEMCWyOUp`;
// Tracking id for Pageviews - staging
export const GA_PAGE_VIEW_TRACKING_STAGING_ID = `${GA_TRACKING_ACCOUNT}/JGcVCOusqqwYEMCWyOUp`;
// Tracking id for CompleteRegistration - staging
export const GA_COMPLETE_REGISTRATION_TRACKING_STAGING_ID = `${GA_TRACKING_ACCOUNT}/Fm5bCOisqqwYEMCWyOUp`;

// Tracking id - sanity test
export const GA_TEST_PAGE_VIEW_TRACKING_ID =
  'AW-11218848576/98tBCKX5yawYEMCWyOUp';

export class GoogleAdsConversionTracker {
  trackPageViewTest = (): void => {
    if (window.gtag !== undefined) {
      window.gtag('event', 'conversion', {
        send_to: GA_TEST_PAGE_VIEW_TRACKING_ID,
      });
    }
  };

  trackPageView = (): void => {
    if (window.gtag !== undefined) {
      window.gtag('event', 'conversion', {
        send_to:
          environment === 'development'
            ? GA_PAGE_VIEW_TRACKING_STAGING_ID
            : GA_PAGE_VIEW_TRACKING_ID,
      });
    }
  };

  trackCompleteRegistration = (account_id: string): void => {
    if (window.gtag !== undefined) {
      window.gtag('event', 'conversion', {
        send_to:
          environment === 'development'
            ? GA_COMPLETE_REGISTRATION_TRACKING_STAGING_ID
            : GA_COMPLETE_REGISTRATION_TRACKING_ID,
        account: account_id,
      });
    }
  };
}

export const GoogleTracker = new GoogleAdsConversionTracker();
